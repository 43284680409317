import {onLoad} from "../../../base/onLoad";
import {sendEvent} from "../../../base/EventSystem";

/**
 * This file is responsible for handling the search form on the map page.
 */
onLoad(() => {
    /**
     * This function is used to debounce function calls to reduce the number of calls to the maps API.
     * @param func
     * @param timeout
     * @returns {(function(...[*]): void)|*}
     */
    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }


    const address_input = document.getElementById('search-address');
    const options = {
        componentRestrictions: {country: ['nl', 'be']}
    }
    const autocomplete = new google.maps.places.Autocomplete(address_input, options);

    /**
     * This function sends a request to the map to search for a location.
     */
    function searchOnMap() {
        // Get the search inputs
        const address = document.getElementById('search-address');
        const radius = document.getElementById('search-radius');

        // check if elements are present
        if (!address || !radius) {
            return;
        }

        // get values
        const addressValue = address.value;
        const radiusValue = radius.value;

        // arguments for request
        const args = {
            address: addressValue,
            radius: radiusValue
        };

        // send request
        sendEvent('search-behandelaars', args);
    }


    // register event listeners
    const address = document.getElementById('search-address');
    const radius = document.getElementById('search-radius');

    if (!address || !radius) {
        return;
    }

    address.addEventListener('change', debounce(searchOnMap));
    radius.addEventListener('change', debounce(searchOnMap));

    function filterBehandelaars() {
        const filter = document.getElementById('search-category');
        let filterValue = filter.value;

        if (!filterValue) {
           filterValue = false;
        }

        sendEvent('filter-behandelaars', {filter: filterValue});
    }


    const cat = document.getElementById('search-category');

    if (!cat) {
        return;
    }

    cat.addEventListener('change', debounce(filterBehandelaars));
});
