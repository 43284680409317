/**
 * This class is used to get coordinates from an address.
 */
export class Geocoder {
    constructor() {
        // initialize the google geocoder
        this.geocoder = new google.maps.Geocoder();
    }

    /**
     * Given a string this function will return the coordinates of the address.
     * @param address
     * @returns {Promise<unknown>}
     */
    async geocode(address) {
        // return a promise, this will be resolved when the geocoder returns the coordinates
        return new Promise((resolve, reject) => {
            // geocode the address
            this.geocoder.geocode({'address': address}, (results, status) => {
                // if the geocode was successful then resolve the promise with the coordinates
                if (status === 'OK') {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();

                    resolve({lat, lng});
                } else {
                    reject(status);
                }
            });
        });
    }
}
