import {onLoad} from "../base/onLoad";

onLoad(() => {
    const filterItems = document.querySelectorAll('.sidebar .filter-item');

    if (!filterItems.length) return;

    const showItem = e => {
        e.setAttribute('aria-expanded', 'true');
    }
    const hideItem = e => {
        e.setAttribute('aria-expanded', 'false');
    }
    const isExpanded = e => {
        return e.getAttribute('aria-expanded') === 'true';
    }

    filterItems.forEach(item => {
        const trigger = item.querySelector('.filter-item__trigger');
        trigger.addEventListener('click', e => {
            e.preventDefault();
            const is_expanded = isExpanded(item);

            // close all
            filterItems.forEach(item => {
                hideItem(item);
            });

            if (is_expanded) {
                hideItem(item);
            } else {
                showItem(item);
            }
        });
    })
});