import {onLoad} from "../../base/onLoad";
import {Splide} from "@splidejs/splide";

onLoad(() => {
    const container = document.querySelector('.behandeling_slider');
    if (!container) return;

    const splide = container.querySelector('.splide');
    if (!splide) return;

    const splideInstance = new Splide(splide, {
        perPage: 4,
        type:'loop',
        gap: '3rem',
        breakpoints: {
            767: {
              perPage: 1,
            },
            991: {
                perPage: 2
            },
            1199: {
                perPage: 3,
            }
        },
        autoplay: true,
    });

    splideInstance.mount();
});
