import {domLoaded} from "../base/onLoad";

domLoaded(() => {
    const dataSrc = document.querySelectorAll('[data-src]');
    let dataSrcArr = Array.from(dataSrc);

    let lazyLoadThrottleTimeout;

    const lazyLoad = () => {
        if (lazyLoadThrottleTimeout) {
            return;
        }

        lazyLoadThrottleTimeout = setTimeout(() => {
            // list of images that were activated
            const done = [];

            // loop through all images that have the data-src attribute
            dataSrcArr.forEach(el => {
                // check if the image is visible in the viewport
                if (el.getBoundingClientRect().top < window.innerHeight && el.getBoundingClientRect().bottom >= 0) {
                    el.src = el.dataset.src;
                    el.removeAttribute('data-src');
                    done.push(el);
                }
            });

            // remove elements that are done
            dataSrcArr = dataSrcArr.filter(el => !done.includes(el));

            lazyLoadThrottleTimeout = null;
        }, 20);
    };

    window.addEventListener('scroll', lazyLoad);
    window.addEventListener('resize', lazyLoad);
    window.addEventListener('orientationchange', lazyLoad);
    window.addEventListener('load', lazyLoad);
});
