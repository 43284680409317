/**
 * This class represents a search area on the map.
 * It is also responsible for checking which markers are inside the search area.
 */
export class SearchPoint {
    constructor(map) {
        this.map = map;

        this.lat = null;
        this.lng = null;
        this.radius = null;

        this.circle = null;
    }

    /**
     * This function creates a circle on the map. which will be used to search for markers.
     * @param lat
     * @param lng
     * @param radius
     */
    setSearchPoint(lat, lng, radius) {
        // start by removing the circle
        this.remove();

        // set the new values
        this.lat = lat;
        this.lng = lng;
        this.radius = radius;

        // create the circle
        this.circle = new google.maps.Circle({
            strokeColor: '#9ec9eb',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#9ec9eb',
            fillOpacity: 0.35,
            map: this.map,
            center: {lat: lat, lng: lng},
            radius: radius
        });

        // move map to the new position
        this.focus();
    }

    /**
     * This function removes the circle from the map.
     */
    remove() {
        if (this.circle) {
            this.circle.setMap(null);
        }
    }

    /**
     * Move the map to the center of the search area.
     */
    focus() {
        this.map.fitBounds(this.circle.getBounds());
    }

    /**
     * This function checks if a point is inside the search area.
     * @param {number} lat
     * @param {number} lng
     * @returns {boolean}
     */
    isInRadius(lat, lng) {
        return this.circle.getBounds().contains({lat: lat, lng: lng});
    }

    getDistance(lat, lng) {
        return google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(this.lat, this.lng),
            new google.maps.LatLng(lat, lng)
        );
    }
}
