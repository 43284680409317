import {onLoad} from '../base/onLoad';

window.mobileMenuOpen = false;

onLoad(() => {
    const mobileMenu = document.querySelector('.mobile_menu');

    if(!mobileMenu) return;

    const mobileMenuTriggers = document.querySelectorAll('.mobile_menu_trigger');

    function renderMobileMenu() {
        const headerHeight = document.querySelector('.main-header').offsetHeight;

        mobileMenu.setAttribute('aria-expanded', mobileMenuOpen? 'true' : 'false');

        if(window.mobileMenuOpen) {
            mobileMenuTriggers.forEach((trigger) => {
                trigger.classList.add('open');
            });

            document.body.classList.add('mobile_menu_open');

            mobileMenu.style.paddingTop = `${headerHeight}px`;

        } else {
            mobileMenuTriggers.forEach((trigger) => {
                trigger.classList.remove('open');
            });

            document.body.classList.remove('mobile_menu_open');
        }
    }

    mobileMenuTriggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            window.mobileMenuOpen = !window.mobileMenuOpen;
            renderMobileMenu();
        });
    });

    renderMobileMenu();

    const mobileSubMenus = mobileMenu.querySelectorAll('.menu-item-has-children');

    function buildSubMenu(e) {
        const trigger = e.querySelector('a:first-of-type');
        const subMenu = e.querySelector('.sub-menu:first-of-type');

        const triggerText = trigger.textContent;

        const showMore = "<i class='fas fa-angle-down'></i>";
        const showLess = "<i class='fas fa-angle-up'></i>";

        function render(initial=false) {
            if(initial) {
                trigger.innerHTML = triggerText + showLess;
                return;
            }

            const isOpen = subMenu.getAttribute('aria-expanded') === 'true';

            if(isOpen) {
                trigger.innerHTML = triggerText + showLess;
            } else {
                trigger.innerHTML = triggerText + showMore;
            }

            subMenu.setAttribute('aria-expanded', !isOpen);
        }

        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            render();
        });

        render(true);
    }

    mobileSubMenus.forEach(subMenu => {
        buildSubMenu(subMenu);
    });
});