import {onLoad} from "../base/onLoad";

onLoad(() => {
    const faq_items = document.querySelectorAll('.faq-item');

    if (!faq_items) return;

    function updateFaqItem(element, show) {
        element.setAttribute('aria-expanded', show ? 'true' : 'false');
    }

    function isFaqItemOpen(element) {
        return element.getAttribute('aria-expanded') === 'true';
    }

    let first = true;

    faq_items.forEach(item => {
        updateFaqItem(item, first);
        first = false;

        const trigger = item.querySelector('.faq-trigger');

        trigger.addEventListener('click', () => {
            // check if the item is open
            const isOpen = isFaqItemOpen(item);

            // close all other items
            faq_items.forEach(item => {
                updateFaqItem(item, false);
            });

            // open or close the clicked item
            updateFaqItem(item, !isOpen);
        });
    });
});
