import {onLoad} from "../../base/onLoad";
import {BehandelaarItem} from "./BehandelaarItem";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {CustomMapsClusterRenderer} from "./CustomMapsClusterRenderer";
import {SearchPoint} from "./SearchPoint";
import {Geocoder} from "./Geocoder";
import {onEvent, sendEvent} from "../../base/EventSystem";

import './handlers';
import {BehandelaarsList} from "./BehandelaarsList";

/**
 * This class is responsible for the behandelaars map.
 */
export class BehandelaarsMap {
    constructor(element) {
        // dom element that will contain the map
        this.mapElement = element;

        // initial map settings
        this.lat = 52;
        this.lng = 5;
        this.zoom = 6;

        this.lastSearchWasSuccessful = false;

        this.behandelaars = [];
        this.includedInSearch = [];
        this.filteredIds = [];

        // this variable is used for custom google map styling
        this.mapId = '8e807a910017d29b';

        // initialize the map
        this.init();

        // initialize the searchpoint class
        this.searchPoint = new SearchPoint(this.map);
        // initialize the geocoder class
        this.Geolocator = new Geocoder();
        // initialize the behandelaarlist class
        this.behandelaarsList = new BehandelaarsList(this);

        this.cluster = null;

        this.search_query = {
            address: '',
            radius: '',
        }

        // start listening for events
        this.events();
    }

    /**
     * This function has all the event listeners for the map
     */
    events() {
        const _this = this;

        // This event is fired when the user tries to search for a location
        onEvent('search-behandelaars', async function (e) {
            const {address, radius} = e.detail;

            _this.search_query = {
                address,
                radius,
            };

            await _this.handleSearch();
        });

        onEvent('search-behandelaars-reset', function () {
            _this.resetSearch();
        });

        onEvent('behandelaar-item-clicked', function (e) {
            const {id} = e.detail;
            const item = _this.findBehandelaar(id);

            console.log(item);

            if (item) {
                _this.map.setZoom(16);
                _this.map.panTo(item.marker.position);

                item.openInfoWindow();
            }
        });

        onEvent('filter-behandelaars', async function (e) {
            const {filter} = e.detail;

           const filteredBehandelaars = _this.behandelaars.filter(behandelaar => {
                const hasTerms = behandelaar.data.terms !== false;

                if(!hasTerms) {
                    return true;
                }

                return behandelaar.data.terms.indexOf(filter) !== -1;
            }).map(x => x.id);

           _this.filteredIds = filteredBehandelaars;

           _this.behandelaars.forEach(i => {
               filteredBehandelaars.indexOf(i.id) !== -1 ? i.show() : i.hide();
           });

           _this.resetClusters();

           await _this.handleSearch();
        });
    }

    /**
     * This function initializes the map
     */
    init() {
        this.map = new google.maps.Map(this.mapElement, {
            center: this.getLatLng(),
            zoom: this.zoom,
            // map id is used for custom map styling
            mapId: this.mapId,
            // disable controls
            mapTypeControl: false,
        });
    }

    async handleSearch() {
        const {address, radius} = this.search_query;

        // check if the search is valid
        if (!address || !radius) {
            if (this.lastSearchWasSuccessful) {
                this.resetSearch();
                this.lastSearchWasSuccessful = false;
            }
            return;
        }

        // parse float radius
        const radiusInMeters = parseFloat(radius) * 1000;

        // get the coordinates of the search
        const filteredBehandelaars = await this.search(address, radiusInMeters);

        // if the search was successful, return the filtered behandelaars
        sendEvent('search-behandelaars-complete', {
            behandelaars: filteredBehandelaars
        });

        this.lastSearchWasSuccessful = true;
    }

    /**
     * This function uses the Google MarkerClusterer to group markers
     */
    createClusters() {
        // get all markers from behandelaars
        const markers = this.getBehandelaars().map(i => i.marker);

        this.cluster = new MarkerClusterer({
            // custom map
            map: this.map,
            // list of markers
            markers: markers,
            // custom styles
            renderer: new CustomMapsClusterRenderer()
        });
    }

    resetClusters() {
        if(this.cluster) {
            this.cluster.clearMarkers();
        }

        this.createClusters();
    }

    /**
     * this function returns the current latlng of the map
     * @returns {{lng: number, lat: number}}
     */
    getLatLng() {
        return {
            lat: this.lat,
            lng: this.lng
        }
    }

    /**
     * This function is used to add a behandelaar to the map.
     * @param {number|string} lat
     * @param {number|string} lng
     * @param {number|string} id
     * @param {string} title
     * @param {array} data
     * @returns {BehandelaarItem}
     */
    registerBehandelaar(lat, lng, id, title, data = []) {
        const behandelaar = new BehandelaarItem(this.map, lat, lng, id, title, data);
        this.behandelaars.push(behandelaar);
        return behandelaar;
    }

    /**
     * This function finds a behandelaar by id
     * @param {number|string} id
     * @returns {BehandelaarItem}
     */
    findBehandelaar(id) {
        id = parseInt(id);
        return this.behandelaars.find(behandelaar => behandelaar.id === id);
    }

    /**
     * This function positions and zooms the maps so that all markers are visible.
     */
    fitToMarkers() {
        // Create a new viewpoint bound
        const bounds = new google.maps.LatLngBounds();
        // add all markers to bounds
        this.getBehandelaars().forEach(behandelaar => {
            bounds.extend({
                lat: behandelaar.lat,
                lng: behandelaar.lng
            });
        });
        // fit map to bounds
        this.map.fitBounds(bounds);
    }

    /**
     * This function takes an address and a radius in meters and returns a list of BehandelaarItems that are within the radius.
     * It also places a circle on the map that shows the radius.
     * @param {string} address
     * @param {number} radius
     * @returns {Promise<[]>}
     */
    async search(address, radius = 5000) {
        // get lat lng from address
        const location = await this.Geolocator.geocode(address);
        // place a circle on the map
        this.searchPoint.setSearchPoint(location.lat, location.lng, radius);

        // get all behandelaars within the radius
        this.includedInSearch = this.getBehandelaars().filter(behandelaar => {
            return this.searchPoint.isInRadius(behandelaar.lat, behandelaar.lng);
        });

        // return the list of behandelaars that are within the radius
        return this.includedInSearch;
    }

    /**
     * This function resets the search.
     * It removes the circle and resets the list
     */
    resetSearch() {
        // reset list
        this.behandelaarsList.filter([]);
        // empty search items
        this.includedInSearch = [];
        // remove search point
        this.searchPoint.remove();
        // reset map and zoom back out
        this.fitToMarkers();
    }

    getBehandelaars() {
        const isFiltered = this.filteredIds.length > 0;
        return this.behandelaars.filter(behandelaar => {
            return isFiltered ? this.filteredIds.indexOf(behandelaar.id) !== -1 : true;
        });
    }

    /**
     * @param {number[]} ids
     * @returns {number[]}
     */
    sortIdsByDistance(ids) {
        const behandelaars = this.idsToBehandelaars(ids);

        const sorted = behandelaars.sort((a, b) => {
            return this.searchPoint.getDistance(a.lat, a.lng) - this.searchPoint.getDistance(b.lat, b.lng);
        });

        return sorted.map(i => i.id);
    }

    /**
     * @param {number[]} ids
     * @returns {BehandelaarItem[]}
     */
    idsToBehandelaars(ids) {
        const behandelaars = ids.map(id => {
            return this.findBehandelaar(id);
        });

        return behandelaars.filter(i => i !== undefined);
    }
}

onLoad(() => {
    // this function creates a new map and returns it
    window.InitBehandelaarsMap = function (element) {
        if(window.behandelaarsMap) {
            return window.behandelaarsMap;
        } else {
            window.behandelaarsMap = new BehandelaarsMap(element);
            return window.behandelaarsMap;
        }
    }

    window.getBehandelaarsMap = function () {
        if(window.behandelaarsMap) {
            return window.behandelaarsMap;
        }

        return null;
    }
    // emit event when the map class is loaded
    sendEvent('map-functions-loaded');
});
