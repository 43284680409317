import {onLoad} from "../../base/onLoad";
import {Splide} from "@splidejs/splide";

onLoad(() => {
    const container = document.querySelector('.machine_slider');
    if (!container) return;

    const splide = container.querySelector('.splide');
    if (!splide) return;

    const splideInstance = new Splide(splide, {
        type: 'loop',
        perPage: 3,
        gap: '6rem',
        breakpoints: {
            768: {
                perPage: 1,
            },
            1100: {
                perPage: 2,
                gap: '40px'
            }
        },
        autoplay: true,
    });

    splideInstance.mount();

    const hideArrow = (arrow) => {
        arrow.classList.add('hide');
    }
    const showArrow = (arrow) => {
        arrow.classList.remove('hide');
    }

    const prevArrow = container.querySelector('.splide__arrow--prev');
    const nextArrow = container.querySelector('.splide__arrow--next');

    function checkArrows(newIndex) {
        const total = splideInstance.length;
        const current = newIndex + 1;

        const canGoLeft = current > 1;

        if (canGoLeft) {
            showArrow(prevArrow);
        } else {
            hideArrow(prevArrow);
        }

        const canGoRight = current < total - 1;

        if (canGoRight) {
            showArrow(nextArrow);
        } else {
            hideArrow(nextArrow);
        }
    }

    // splideInstance.on('move', (newIndex, prevIndex, destIndex) => {
    //     checkArrows(newIndex);
    // });
    //
    // checkArrows(splideInstance.index);
});
