import {onEvent, sendEvent} from "../../base/EventSystem";

/**
 * This class is used to manage the behandelaars list.
 *
 */
export class BehandelaarsList {

    /**
     * @param {BehandelaarsMap} behandelaarsMap
     */
    constructor(behandelaarsMap) {
        // The behandelaars map
        this.behandelaarsMap = behandelaarsMap;

        // get items in the list
        this.items = document.querySelectorAll('.behandelaar-item');


        this.whitelist = [];
        this.currentItems = [];

        this.items.forEach(item => {
            item.addEventListener('click', () => {
                this.handleClick(item);
            });
        });

        // get dom elements
        this.prevButton = document.querySelector('.behandelaars-list-prev');
        this.nextButton = document.querySelector('.behandelaars-list-next');
        this.counter = document.querySelector('.behandelaars-list-counter');
        this.page = 1;

        // set the maximum amount of items in the list
        this.maxVisible = 5;

        // start listening to events
        this.handleEvents();

        // render the list
        this.render();
    }

    sortHtmlItems() {
        const map = window.getBehandelaarsMap();

        if(!map) {
            return;
        }

        const items = this.items;
        const ids = [];
        items.forEach(item => {
           const id = this.getIdFromElement(item);
           ids.push(id);
        });

        const sorted = map.sortIdsByDistance(ids);
        const sortedItems = sorted.map(id => this.getItemById(id));

        let parent = null;

        sortedItems.forEach((item, index) => {
            parent = item.parentNode;
            parent.removeChild(item);
        });

        sortedItems.forEach((item, index) => {
            parent.appendChild(item);
        });
    }

    /**
     * This function handles the events.
     */
    handleEvents() {
        // start filtering the list
        onEvent('search-behandelaars-complete', (e) => {
            /** @type {Array<BehandelaarItem>} */
            const {behandelaars} = e.detail;

            this.filter(behandelaars.map(i => i.id));
        });

        // navigation
        if (this.nextButton) {
            this.nextButton.addEventListener('click', () => {
                this.navigate('>');
            });
        }
        if (this.prevButton) {
            this.prevButton.addEventListener('click', () => {
                this.navigate('<');
            });
        }
    }

    /**
     * This function is responsible for navigating through the list
     * @param direction
     */
    navigate(direction) {
        if (direction === '>') {
            this.page++;
            if (this.page > Math.ceil(this.currentItems.length / this.maxVisible)) {
                this.page = 1;
                this.render();
                return;
            }
        } else if (direction === '<') {
            this.page--;
            if (this.page < 1) {
                this.page = 1;
                this.render();
                return;
            }
        }

        // if the direction is an integer. set the page to that value;
        if (!isNaN(direction)) {
            this.page = parseInt(direction);
        }

        this.render();
    }

    /**
     * This function registers when the user clicks on an item in the list.
     * @param element
     */
    handleClick(element) {
        // send an event to the map
        sendEvent('behandelaar-item-clicked', {
            id: this.getIdFromElement(element),
        });
    }

    /**
     * This function returns the id of the item from the element.
     * @param element
     * @returns {number}
     */
    getIdFromElement(element) {
        const id = element.getAttribute('data-id');
        return parseInt(id);
    }

    /**
     * This function filters the list.
     * @param ids
     */
    filter(ids) {
        // white list the chosen ids
        this.whitelist = ids;
        // reset page to 1
        this.page = 1;
        // render the list
        this.render();
    }

    /**
     * This function renders the list.
     */
    render() {
        // hide all items
        this.items.forEach(item => {
            this.updateElement(item, true);
        });

        // check if it should render the whitelist
        const hasWhitelist = this.whitelist.length > 0;
        let showControls = true;
        let ids = [];
        if (hasWhitelist) {
            ids = this.whitelist;
        } else {
            showControls = false;
            // this.items.forEach(item => {
            //     ids.push(this.getIdFromElement(item));
            // });
        }

        const controls = document.querySelector('.sidebar-controls');
        if (showControls) {
            controls.classList.remove('hidden');
        } else {
            controls.classList.add('hidden');
        }

        // get dom elements by ids
        const elements = ids.map(i => this.getItemById(i)).filter(Boolean);

        // save the current items
        this.currentItems = elements;

        // show the items if they are one the current page
        elements.forEach((element, index) => {
            const notBefore = index < this.page * this.maxVisible;
            const notAfter = index >= (this.page - 1) * this.maxVisible;

            this.updateElement(element, !(notBefore && notAfter));
        });

        // update the counter
        this.updateCounter(Math.ceil(ids.length / this.maxVisible), this.page);

        setTimeout(() => {
            this.sortHtmlItems();
        }, 2000);
    }

    /**
     * This function updates the counter.
     * @param {string|number} max
     * @param {string|number} page
     */
    updateCounter(max, page) {
        this.counter.innerHTML = `${page} / ${max}`;
    }

    /**
     * This function get a dom element by id
     * @param {number|string} id
     * @returns {HTMLElement|null}
     */
    getItemById(id) {
        let result = null;
        this.items.forEach(item => {
            if (this.getIdFromElement(item) === id) {
                result = item;
            }
        });

        return result;
    }

    /**
     * This function is used to show or hide a list element.
     * @param element
     * @param hide
     */
    updateElement(element, hide = true) {
        const attr = 'aria-hidden';
        const value = hide ? 'true' : 'false';
        element.setAttribute(attr, value);
    }
}
