/**
 * This class represents a single behandelaar item.
 * It is responsible for handeling the behandelaar item.
 */
import {onEvent} from "../../base/EventSystem";

export class BehandelaarItem {

    constructor(map, lat, lng, id, title, data = []) {
        // google map object
        this.map = map;

        // set coordinates
        this.lat = lat;
        this.lng = lng;

        // parse coordinates
        this.fixLatLng();

        // set other properties
        this.id = id;
        this.title = title;
        this.data = data;

        this.marker = null;
        this.infoWindow = null;

        // initialize the behandelaar item
        this.init();
    }

    /**
     * this function makes sure that the coordinates are in the correct format
     */
    fixLatLng() {
        // if lat or lng is a string try to parse it
        if (typeof this.lat === 'string') {
            this.lat = parseFloat(this.lat);
        }
        if (typeof this.lng === 'string') {
            this.lng = parseFloat(this.lng);
        }
    }

    /**
     * this function initializes the behandelaar item
     */
    init() {
        // create info window
        this.createInfoWindow();
        // create marker
        this.createMarker();

        // start listening to events
        this.registerEvents();
    }

    /**
     * this function is responsible for listening to events
     */
    registerEvents() {
        // this event is fired when all the info windows have to be closed
        onEvent('close-info-window', () => {
            // close the infowindow
            this.infoWindow.close();
        });

        // if the marker is clicked open the infowindow
        this.marker.addListener('click', () => {
            this.openInfoWindow();
        });
    }

    /**
     * This function is responsible for opening the info window
     */
    openInfoWindow() {
        // close all other info windows
        this.closeWindows();
        // open the info window
        this.infoWindow.open({
            map: this.map,
            anchor: this.marker,
            shouldFocus: false,
        });
    }

    /**
     * This function closes all the info windows
     */
    closeWindows() {
        window.dispatchEvent(new CustomEvent('close-info-window'));
    }

    /**
     * This function is responsible for creating the info window
     * @returns {google.maps.InfoWindow}
     */
    createInfoWindow() {
        // get data for the info window
        const {content, title, test} = this.data;

        // if there is content use it
        const description = content ? `<div class="description">${content}</div>` : '';

        // info window html
        let htmlContent = `
            <div class="behandelaar-info-window">
                <h3>${this.title}</h3>
                
                ${description}
            </div>
        `;

        // create info window
        this.infoWindow = new google.maps.InfoWindow({
            content: htmlContent
        });

        // return the info window
        return this.infoWindow;
    }

    /**
     * This function returns a custom icon that is used for the markers.
     * @returns {{scaledSize: google.maps.Size, origin: google.maps.Point, anchor: google.maps.Point, url: string}}
     */
    getIcon() {
        // url of the icon
        const url = window.customIconPath;
        // size of the icon
        const actualWidth = 36;
        const actualHeight = 47;

        // calculate the scaled size
        const width = actualWidth * 0.5;
        const height = actualHeight * 0.5;

        return {
            url,
            scaledSize: new google.maps.Size(width, height),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(width / 2, height),
        };
    }

    /**
     * This function is responsible for creating the marker
     * @returns {google.maps.Marker}
     */
    createMarker() {
        this.marker = new google.maps.Marker({
            position: {lat: this.lat, lng: this.lng},
            map: this.map,
            title: this.title,
            icon: this.getIcon()
        });
        return this.marker;
    }

    /**
     * This function makes the map focus on the marker
     */
    focus() {
        const newCenter = new google.maps.LatLng(this.lat, this.lng);
        this.map.panTo(newCenter);
    }

    hide() {
        this.marker.setMap(null);
    }

    show() {
        this.marker.setMap(this.map);
    }
}
