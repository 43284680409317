import {onLoad} from "../base/onLoad";

onLoad(() => {
    // menu toggles
    const toggles = document.querySelectorAll('[menu-toggle]');
    // menu items
    const menu = document.querySelector('[menu]');

    // loop over toggles
    toggles.forEach(toggle => {
        // on click
        toggle.addEventListener('click', () => {
            // toggle menu
            menu.classList.toggle('open');
        });
    });
});
