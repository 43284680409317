export function sendEvent(eventName, eventData) {
    const event = new CustomEvent(eventName, {
        detail: eventData
    });
    window.dispatchEvent(event);
}

export function onEvent(event, callback) {
    window.addEventListener(event, callback);
}
