import {DefaultRenderer} from "@googlemaps/markerclusterer";

/**
 * This class extends the DefaultRenderer from google.
 * It overrides the render function to add custom styling to the clusters
 */
export class CustomMapsClusterRenderer extends DefaultRenderer {
    /**
     * This function returns a hex color based on the number of markers in the cluster
     *
     * @param {number} count
     * @param {number} maxCount
     * @returns {string}
     */
    getColor(count, maxCount) {
        // array of all possible colors
        const colors = [
            "#77b3e3",
            "#59a3dd",
            "#3b92d7",
            "#226ca6",
            "#1c5888",
            "#16456a",
            "#10314b",
            "#091d2d",
            "#091d2d",
        ];

        const colorAmount = colors.length;

        function numberMap(number, in_min, in_max, out_min, out_max) {
            return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
        }

        // get index of color based on number of markers in cluster
        const colorIndex = Math.round(numberMap(count, 0, maxCount, 0, colorAmount - 1));

        // return color
        return colors[colorIndex];
    }

    /**
     * This function overrides the render function to add custom styling to the clusters
     *
     * @returns {google.maps.Marker}
     */
    render({count, position}, stats) {
        // get cluster with the most markers
        const max = stats.clusters.markers.max;
        // get color based on number of markers in cluster
        const color = this.getColor(count, max);
        // create svg url with fill color
        const svg = window.btoa(`
          <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity=".6" r="70" />
            <circle cx="120" cy="120" opacity=".3" r="90" />
            <circle cx="120" cy="120" opacity=".2" r="110" />
          </svg>`);
        // create marker using svg icon
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
            },
            label: {
                text: String(count),
                color: "rgba(255,255,255,0.9)",
                fontSize: "12px",
            },
            title: `Cluster of ${count} markers`,
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
    }
}
